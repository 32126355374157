import React, { useState, useEffect } from "react"

// Dependencies
import { Container, Row, Col, Button } from "react-bootstrap"
import { navigate } from "gatsby"
import Select from "react-dropdown-select";

// Components

import CustomDropDown from "../../../components/CustomDropdown"
import {GetRegionAPI} from "../../../components/common/agentAPI"
import CustomRadioButton from "../../../components/CustomRadioButton"
import {
    homePriceRange,
	homeBedroomOptions,
	priceminRange,
	pricemaxRange,
	bedroomOptions
} from "../../../components/common/propertyUtils";

// Styles
import "./SearchForm.scss"

const defaultSearchFormData = {
	area: 'All Areas',
	priceRange: '500 - 1000',
	bedroom: '1',
	lettingsType: 'Long Let',
}

const lettingsTypeOptions = [
	'Long Lets',
	'Short Lets'
]

const SearchForm = ( { menuData } ) => {

	const [ area_list, setAreaList ] = useState([]);
	

	useEffect(()=>{
        GetRegionAPI().then(async apiRes => {
            if(apiRes?.success === true){
                setAreaList(apiRes.results)
            }
        });
    },[])

	const [ searchFormData, setSearchFormData ] = useState( defaultSearchFormData );

	const onChange = ( name, value ) => {
		if ( name && value ) {
			const updatedSearchFormData = {
				...searchFormData,
				[ name ]: value
			}
			setSearchFormData( updatedSearchFormData )
		}
	}

	// Area
	const [areaVal, setAreaVal] = useState('');
	const onChangeArea = (name) => {
        setAreaVal(name);
    }

	// LettingType
	const [lettingsType, setLettingType] = useState('Long Lets');
	const onChangeLettings = (name, option) => {

        setLettingType(option);
    }
	

	// Price range
	const [priceVal, setPriceVal] = useState('');
	const onChangePrice = (e) => {
        setPriceVal(e[0].value);
    }
	// Price range

	// Min Price range
	const [minpriceVal, setMinPriceVal] = useState(0);
	const onChangeMinPrice = (e) => {
        setMinPriceVal(e[0].value);
    }
	// Min Price range

	// Max Price range
	const [maxpriceVal, setMaxPriceVal] = useState(0);
	const onChangeMaxPrice = (e) => {
        setMaxPriceVal(e[0].value);
    }
	// Max Price range

	// Bedroom
	const [bedroomVal, setBedroomVal] = useState('');
	const onChangeBedroom = (e) => {
        setBedroomVal(e[0].value);
    }
	// Bedroom


    // Lettings price list
	var minPriceLettings = [];
	var maxPriceLettings = pricemaxRange.filter(c => (c.value === "0" || parseInt(c.value) > parseInt(minpriceVal)));

	priceminRange.map(items => parseInt(items.value) < parseInt(maxpriceVal) && minPriceLettings.push(items));

	if (minPriceLettings.length > 0) {
	} else {
		minPriceLettings.push(priceminRange[0])
	}

	if (maxPriceLettings.length > 0) {
	} else {
		maxPriceLettings.push(pricemaxRange[0])
	}
	// Lettings price list

	// Submit search
	const submitSearch = (e) => {
		var areaValue = areaVal;
		var priceRangeVal = priceVal;
		var bedVal = bedroomVal;
		var lettings = lettingsType;
		var minprice = minpriceVal;
		var maxprice = maxpriceVal;
		
		var searchFilterResults = "";

		if(areaValue) {
			searchFilterResults = "/residential/property/to-rent/in-" + areaValue.replace(/ /g, '-').toLowerCase() + "/";
		} else {
			searchFilterResults = "/residential/property/to-rent/in-london/";
		}

		if(lettings){
			searchFilterResults += "type-"+lettings.toLowerCase().replace(/ /g, '-')+"/";
		}

		// let priceUrl = ""
		// if(priceRangeVal && priceRangeVal !="0") {
		// 	var priceRangearr = priceRangeVal.split("-");
		// 	priceUrl = "between-"+priceRangearr[0]+"-and-"+priceRangearr[1]
		// }

		let priceUrl = ""
		if (minprice || maxprice) {
		if (minprice && maxprice && minprice != "0" && maxprice != "0") {
			priceUrl = "between-" + minprice + "-and-" + maxprice
		} else
			if ((minprice == '' || minprice == "0") && maxprice && maxprice != "0") {
				priceUrl = "under-" + maxprice
			}
			else if (minprice && (maxprice == '' || maxprice == "0") && minprice != "0") {
				priceUrl = "above-" + minprice
			}
		}

		if(bedVal && bedVal != "0"){
			searchFilterResults += bedVal + "-and-more-bedrooms/";
		}

        if(priceUrl){
			searchFilterResults += priceUrl +"/";
		}
		

		// console.log("$$$$$--result--", searchFilterResults)
		navigate(searchFilterResults);
	}
	// Submit search


	const areasOptions = [{display: 'All Locations',value: 'All Areas'}];
	area_list.length > 0 && area_list.map(item => areasOptions.push({display: item.name,value: item.name}))
	

	return (
		<div className="search-form-wrapper test">
			<Container>
				<Row>
					<Col>
						<CustomDropDown
							name="area"
							label=""
							defaultOption={ areasOptions[ 0 ] }
							options={ areasOptions }
                            variant="black"
							onChangeCallback={ onChangeArea }
						/>

					</Col>
					<Col sm={ 6 } lg={ 3 }>
						<div className="form-field custom-dropdown-wrapper white custom-selectbox-field">
							 
							<div className="select-boxes home-search-selectbox pricerang_select">
								{/* <Select 
									placeholder={"Price Range"}
									searchable={false}
									options={homePriceRange}
									defaultValue={""}
									onChange={e => { onChangePrice(e); setPriceVal(e[0].value) }}
									className=''
								/> */}

								{/* <Select 
									placeholder={"Min. Price"}
									searchable={false}
									options={priceminRange}
									defaultValue={""}
									onChange={e => { onChangeMinPrice(e); }}
									className=''
								/> */}

								<Select 
									placeholder={"Min. Price"}
									searchable={false}
									options={parseInt(maxpriceVal) === 0 ? priceminRange : minPriceLettings}
									defaultValue={priceminRange.find(obj => obj.value === minpriceVal)}
									onChange={e => { onChangeMinPrice(e);}}
									className='min-price'
								/>

								{/* <Select 
									placeholder={"Max. Price"}
									searchable={false}
									options={pricemaxRange}
									defaultValue={""}
									onChange={e => { onChangeMaxPrice(e);}}
									className=''
								/> */}

								<Select 
									placeholder={"Max. Price"}
									searchable={false}
									options={parseInt(minpriceVal) === 0 ? pricemaxRange :  maxPriceLettings}
									defaultValue={pricemaxRange.find(obj => obj.value === maxpriceVal)}
									onChange={e => { onChangeMaxPrice(e); }}
									className=''
								/>
							</div>
						</div>
					</Col>
					<Col sm={ 6 } lg={ 2 }>
						<div className="form-field custom-dropdown-wrapper white custom-selectbox-field">
							{/* <label className="custom-label">Bedroom</label> */}
							<div className="select-boxes home-search-selectbox">
								<Select 
									placeholder={"Bedroom"}
									searchable={false}
									options={bedroomOptions}
									defaultValue={""}
									onChange={e => { onChangeBedroom(e); setBedroomVal(e[0].value) }}
									className=''
								/>
							</div>
						</div>
					</Col>
					<Col sm={ 6 } lg={ 3 }>
						<CustomRadioButton
							isInline
							name="lettingsType"
							label=""
							options={ lettingsTypeOptions }
							defaultOption={ lettingsTypeOptions[ 0 ] }
							onChangeCallback={ onChangeLettings }
						/>
					</Col>
					<Col>
						<div className="custom-button-wrapper">
							<Button type="button" variant={ "black" } onClick={submitSearch}>SEARCH</Button>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	)
}

export default SearchForm
