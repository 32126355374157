import React from 'react';

const PlusIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M8 2V14M14 8L2 8" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
);
  
  const MinusIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M2 8H14" stroke="white" stroke-width="1.2" stroke-linecap="round"/>
    </svg>
  );
export {
    PlusIcon,
    MinusIcon,
}