import React, { useState, useEffect } from "react"
import { useLocation } from "@reach/router"
import { Container, Row, Col} from "react-bootstrap";

// Components
import SectionWrapper from "../SectionWrapper";
import OffMarketForm from "./OffMarketForm";

import Contact_Form from "../forms/contact-form";
import Career_Form from "../forms/career-form";
import OffMarket_Form from "../forms/offmarket-properties";
import Sell_To_Us_Form from "../forms/sell-to-us-form";
import Member_Form from "../forms/member-form";
import Negdetails from "components/ContactCard/Negdetails";


// Styles
import './forms_styles.scss';

const FormSection = ( { Module, to_email_id, NegoDetails  } ) => {
    const location = useLocation();
    //console.log("Module ==>", GQLPage.id)
    //console.log("Modlue::Type::", Module.Form_Type)

    const [showNegotiator, setShowNegotiator] = useState(false);
    const [ContactForm, setContactForm] = useState("Career_Form");
    const [isDesktop, setIsDesktop] = useState(false);

    useEffect(() => {
        if (location.pathname === '/contact-us/' || location.pathname === '/contact-us') {
            setShowNegotiator(true);
            setContactForm("Contact_Form")
        } else {
            setShowNegotiator(false);
        }

        const updateScreenSize = () => {
            setIsDesktop(window.innerWidth > 1200);
        };
        updateScreenSize();
        window.addEventListener("resize", updateScreenSize);
        return () => {
            window.removeEventListener("resize", updateScreenSize);
        };
    }, [location]); 

    const isContactPage = location.pathname === '/contact-us/' || location.pathname === '/contact-us';
    
    if (isContactPage && isDesktop) {
        return null; 
    }

    return (
        <SectionWrapper className={`off-market-properties-wrapper ${Module.Form_Type === ContactForm ? "white_bg" : ""}`}>
            <Container>
            <Row>
                <Col lg={showNegotiator ? 8 : 12}>
                <div className="off-market-properties-header">
                    {Module.Title && 
                    <h2 className="general-heading">
                        {Module.Title}
                    </h2>
                    }
                    
                    {Module.Content && 
                    <p className={ `general-subheading` }>
                        { Module.Content }
                    </p>
                    }   
                </div>
                
                <div className="off-market-properties-body">
                    <div className="form-wrapper">

                        {Module.Form_Type === "Off_Market_Form" && <OffMarket_Form />}
                        {Module.Form_Type === "Sell_To_Us_Form" && <Sell_To_Us_Form />}
                        {Module.Form_Type === "Contact_Form" && <Contact_Form />}
                        {Module.Form_Type === "Individual_Contact" && <Member_Form to_email_id={to_email_id} />}
                        {Module.Form_Type === "Career_Form" && <Career_Form />}  
                        {Module.Form_Type === "Career_Details" && <Career_Form job_title={Module.job_title ? Module.job_title : ""} />}                        
                    </div>
                    
                </div>
                </Col>
                { showNegotiator &&
                    <Col lg={4} >
                        <Negdetails Negoslug={NegoDetails?.URL} />
                    </Col>
                }
            </Row>
            </Container>
        </SectionWrapper>
    )
}

export default FormSection
