import * as React from "react"
import { Row, Col, Card } from "react-bootstrap"
import { Link } from "@reach/router";

import {ImageModule} from '../../modules/Image_Module'

import {propertyDetailsLink} from '../common/propertyUtils'

// Styles
import "./SinglePropertyCard.scss"

const SinglePropertyCard = ( { singlePropertyCardData = {}, showLabel } ) => {

	const { Label } = singlePropertyCardData;

	var link = propertyDetailsLink(singlePropertyCardData.department, singlePropertyCardData.search_type, singlePropertyCardData.slug, singlePropertyCardData.id);

	return (
		<div className="single-property-card-wrapper">
			<Card>
				<div className="card-container-image">
					{ showLabel && Label && (
						<span className="image-label">
							{ Label }
						</span>
					) }
					<div class="card-container-image">
						<Link to={ link }>
							<ImageModule classNames={"card-img-top"} ImageSrc={singlePropertyCardData?.images?.length > 0 ? singlePropertyCardData?.images[0] : "https://ggfx-mccone.s3.eu-west-2.amazonaws.com/i.prod/16_min_ec29398f34.png"} />
						</Link>
					</div>
				</div>
				<Card.Body>
					<Card.Title className="card-title">
						<Link to={ link }>{ singlePropertyCardData.display_address }</Link>
					</Card.Title>
					<Row className="card-content">
						{singlePropertyCardData.price && 
						<div className="card-subtitle">
							£{ singlePropertyCardData.price.toLocaleString() } p/w
						</div>
						}
						
						<Col>
							<div className="card-footer-icons">
								<div className="count-icons">
									<i className="icon icon-bedroom"></i>
									<span>{ singlePropertyCardData.bedroom }</span>
								</div>
								<div className="count-icons">
									<i className="icon icon-bathroom"></i>
									<span>{ singlePropertyCardData.bathroom }</span>
								</div>
								<div className="count-icons">
									<i className="icon icon-pet-friendly"></i>
								</div>
							</div>
						</Col>
					</Row>
				</Card.Body>
			</Card>
		</div>
	)
}

export default SinglePropertyCard
