import React, {useEffect} from "react"
import {Link} from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import { LogoWhite, LogoBlack, AgentBlack, AgentWhite, HamBurgerBlack, HamBurgerWhite } from "./HeaderIcons";


// Styles
import "../Header/Header.scss"

// Components
import Menu from "../Menu/index"
import MegaMenuOverlay from "./MegaMenuOverlay"
import { useState } from "react"

import { isLoggedIn} from "../../services/auth"
import useWindowSize from "../common/useWindowSize";

const Header = ( { headerMenu, variant, headerData, menu_Data } ) => {
  const { width } = useWindowSize();
  const [scroll, setScroll] = useState(false);
  const [subMenuId, setSubMenuId] = useState('');
  const [isAuth, setIsAuth] = useState(isLoggedIn())

  const [ selectedIndex, setSelectedIndex ] = useState("");
  const [ showMenu, setShowMenu ] = useState( false );

  useEffect(()=>{
    setTimeout(()=>{
      window.scrollTo(0,0);
    }, 200) 
  },[])

  useEffect(() => {
    document.body.style.overflow = showMenu ? 'hidden' : 'auto';
  }, [showMenu]);

  useEffect(() => {
    try {
      window.addEventListener("scroll", () => {
        if(width < 1200){          
          setScroll(window.scrollY > 40);
        } else if(width > 1200 && width < 1400){
          setScroll(window.scrollY > 70);
        } else{
          setScroll(window.scrollY > 120);
        }
      });
    } catch {}
  }, [width]);
console.log("header", headerMenu );
  return (
    
    <header className={`header ${ scroll ? "header--fixed" : variant || ''}`}>
      <Container fluid>
        <Row className="header-content">
         <Col xs={7}  md={2} className="header-logo">
                <Link to="/" className="logo-wrapper">
                  {variant === "header--transparent"  ? (scroll ? <LogoBlack /> : <LogoWhite /> ): <LogoBlack /> }
                </Link>
          </Col>
          <Col md={8} className="header-main-menu">            
              <Menu
                menuData={headerMenu}               
                isAuth={isAuth}
              />
          </Col>
          <Col xs={5} md={2} className="header-account-menu">
            <div className="header-account-menu-item-wrap agent-menu-wrap">
              {isLoggedIn() ? 
                <Link to="/dashboard/" className="header-account-menu-item">
                  {variant === "header--transparent"  ? (scroll ? <AgentBlack /> : <AgentWhite /> ): <AgentBlack /> }
                  <span className="agent-icon-text">
                    Dashboard
                  </span>
                </Link>
                :
                <Link to="/login/" className="header-account-menu-item">
                 {variant === "header--transparent"  ? (scroll ? <AgentBlack /> : <AgentWhite /> ): <AgentBlack /> }
                  <span className="agent-icon-text">
                    AGENT AREA
                  </span>
                </Link>
              }
              
            </div>
            <div
              className="header-account-menu-item-wrap"
              onClick={ () => {
                setSelectedIndex( null )
                setShowMenu( !showMenu )
              } }
            >
              <a className="header-account-menu-item">
              {variant === "header--transparent"  ? (scroll ? <HamBurgerBlack /> : <HamBurgerWhite /> ): <HamBurgerBlack /> }
              <span>
                MENU
              </span>
              </a>
            </div>
          </Col>
        </Row>
      </Container>
      {
        ( showMenu ) && (
          <MegaMenuOverlay
            menuData={menu_Data}
            showMenu={ showMenu }
            setShowMenu={ setShowMenu }
            selectedIndex={ selectedIndex }
            setSelectedIndex={ setSelectedIndex }
            subMenuId={subMenuId}
            setSubMenu={setSubMenuId}
            isAuth={isAuth}
          />
        )
      }
    </header>
  )
}

export default Header
